.site-container {
    position: static;
    display: block;
    float: none;
    margin-top: 0 !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
}
body {
    overflow-y: visible !important;
    overflow-x: visible !important;
}
html {
    overflow-y: visible !important;
    overflow-x: visible !important;
}
a:link:after, a:visited:after {
    display: none;
    content: "";
}
[class*="col"],
.row [class*="col"] {
    float: none;
    display: block;
    width: auto;
    margin-left: 0;
}

